/** @jsxImportSource @emotion/react */
import { useMemo, useContext, useState } from "react";
import { css } from "@emotion/core";
import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Input,
} from "reactstrap";
import { withFetch } from "./FetchHOC";
import { faMeta, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { deleteAPI } from "../../WebAPI";
import UserContext from "./UserContext";
import { toastr } from "react-redux-toastr";
import { normalizeFilters } from "./filters";

const WatchlistTableLayout = (props) => (
  <Table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Service</th>
        <th>Created</th>
        <th>Query</th>
        <th>Filters</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>{props.children}</tbody>
  </Table>
);

const renderValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((x) => renderValue(x)).join(", ");
  } else if (typeof value === "object") {
    if (value.label) {
      return value.label;
    } else if (value.values) {
      return (value.exclude ? "EXCLUDE " : "") + renderValue(value.values);
    } else if (value.value) {
      return (value.exclude ? "EXCLUDE " : "") + renderValue(value.value);
    } else {
      throw new Error(`Unexpected value ${value}`);
    }
  } else {
    return value.toString();
  }
};

const hasValue = (value) =>
  (Array.isArray(value) && value.length) || (!Array.isArray(value) && value);

const watchlistURL = (watchlist) => {
  let servicePart = null;
  switch (watchlist.service) {
    case "google-ads":
      servicePart = "google";
      break;
    case "facebook-ads":
      servicePart = "meta";
      break;
    default:
      console.error(`Unknown service: ${watchlist.service}`);
  }
  return `/${servicePart}/watchlists/${watchlist.id}/`;
};

const ConfirmDeleteModal = ({ name, onDelete, children }) => {
  const [showModal, setModal] = useState(false);
  const [text, setText] = useState("");

  const toggle = () => setModal(!showModal);

  return (
    <div>
      {children(toggle)}
      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Watchlist</ModalHeader>
        <ModalBody>
          Type the name of the watchlist to confirm delete: <code>{name}</code>
          <Input
            className="mt-2"
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={text.toLowerCase() !== name.toLowerCase()}
            onClick={onDelete}
          >
            Delete
          </Button>{" "}
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const WatchlistTable = ({ watchlists, onDelete, deleted }) => (
  <WatchlistTableLayout>
    {!watchlists.length && (
      <tr>
        <td colSpan="5">No watchlists</td>
      </tr>
    )}
    {watchlists
      .filter((watchlist) => !deleted.includes(watchlist.id))
      .map((watchlist) => (
        <tr key={watchlist.id}>
          <td>
            <Link to={watchlistURL(watchlist)}>{watchlist.name}</Link>
          </td>
          <td>
            <FontAwesomeIcon
              icon={watchlist.service === "google-ads" ? faGoogle : faMeta}
            />
          </td>
          <td>{moment(watchlist.created_at).fromNow()}</td>
          <td>
            <ul
              css={css`
                margin-bottom: 0;
                padding-left: 20px;
              `}
            >
              {Object.entries(watchlist.settings.query._raw).map(
                ([attr, value]) => {
                  if (hasValue(value)) {
                    return (
                      <li key={attr}>
                        {_.startCase(_.camelCase(attr))}: {renderValue(value)}
                      </li>
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </ul>
          </td>
          <td>
            {normalizeFilters(watchlist.settings.filters).map(
              ({ id: attr, value }) => {
                if (hasValue(value)) {
                  return (
                    <li key={attr}>
                      {_.startCase(_.camelCase(attr))}: {renderValue(value)}
                    </li>
                  );
                } else {
                  return null;
                }
              }
            )}
          </td>
          <td>
            <div
              css={css`
                display: flex;
              `}
            >
              <Button
                color="info"
                className="mr-2"
                to={watchlistURL(watchlist)}
                tag={Link}
              >
                Edit
              </Button>

              <ConfirmDeleteModal
                name={watchlist.name}
                onDelete={() => onDelete(watchlist)}
              >
                {(toggle) => (
                  <Button color="danger" onClick={toggle}>
                    Delete
                  </Button>
                )}
              </ConfirmDeleteModal>
            </div>
          </td>
        </tr>
      ))}
  </WatchlistTableLayout>
);

const LoadingTable = () => (
  <WatchlistTableLayout>
    <tr>
      <td colSpan={4}>Loading watchlists...</td>
    </tr>
  </WatchlistTableLayout>
);

export const WatchlistView = () => {
  const [deleted, setDeleted] = useState([]);
  const { token } = useContext(UserContext);

  const FetchedTable = useMemo(
    () =>
      withFetch("/api/watchlists", {
        propName: "watchlists",
        renderLoad: () => <LoadingTable />,
      })(WatchlistTable),
    []
  );

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Watchlists</h1>

      <Row>
        <Col lg="12">
          <Card>
            <FetchedTable
              deleted={deleted}
              onDelete={({ id, name }) => {
                deleteAPI(`/api/watchlists/${id}/`, token)
                  .then(() =>
                    toastr.success(
                      "Deleted Watchlist",
                      `Deleted watchlist ${name}`
                    )
                  )
                  .catch(() => {
                    setDeleted((xs) => xs.filter((x) => x !== id));
                    toastr.warning(
                      "Error",
                      `An error occurred while trying to delete watchlist ${name}.`
                    );
                  });
                setDeleted((xs) => [...xs, id]);
              }}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
