/** @jsxImportSource @emotion/react */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import {
  exportCSV,
  exportDemographicsCSV,
  exportGeoCSV,
  exportJSON,
  exportJSONL,
  exportTargetingCSV,
} from "./export";
import { useDebounce } from "use-debounce";
import SearchBar, { blankQuery } from "./SearchBar";
import UserContext from "../common/UserContext";
import DetailTable from "../common/DetailTable";
import { makeColumns } from "./TableColumns";
import { SearchError, useJSONL, useSearch } from "../common/hooks";
import { useCoordination } from "../common/useCoordination";
import * as _ from "lodash";
import { toastr } from "react-redux-toastr";
import Loader from "react-loader-spinner";
import useWatchlist, { makeQuery } from "./hooks/useWatchlist";
import { ConnectFacebook } from "./hooks/useConnectFacebook";
import useColumnFilters from "./hooks/useColumnFilters";
import CoordinationOption from "./CoordinationOption";
import { anyCoordination } from "./CoordinationModals";
import { normalizeFilters } from "../common/filters";
import {
  CompactMultiSelect,
  defaultColumnOptions,
  liftValue,
} from "../common/CustomSelect";
import "./Facebook.scss";
import { css } from "@emotion/core";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import WatchlistSettings from "../common/WatchlistSettings";
import moment from "moment";
import { useEntityLists } from "../common/useEntityLists";
import FlagContext from "../common/FlagContext";
import ChartCard from "./ChartCard";

const DEFAULT_CHARTS = [
  "creative-cloud",
  "impression-demographics",
  "impression-geography",
];

const FacebookLayout = ({ watchlist, savedSearch = {} }) => {
  const [minCoordinate, setMinCoordination] = useState(50);
  const [tableRows, setTableRows] = useState([]);
  const [debouncedRows] = useDebounce(tableRows, 150);
  const [exportOpen, setExportOpen] = useState(false);
  const { facebookToken } = useContext(UserContext);
  const { flags } = useContext(FlagContext);

  const [filters, setFilters] = useState(
    watchlist ? normalizeFilters(watchlist.settings.filters) : []
  );

  console.debug("Filters", filters);

  const [
    pollingResultId,
    pollingResults,
    onSearch,
    isSearching,
    searchError,
    progress,
  ] = useSearch("/api/facebook/search/");

  const initialQuery = watchlist
    ? watchlist.settings.query._raw
    : savedSearch.query || blankQuery;

  const [query, setQuery] = useState(initialQuery);

  const defaultColumns = useMemo(
    () => defaultColumnOptions(makeColumns(facebookToken, flags)),
    [facebookToken, flags]
  );

  const [activeColumns, setActiveColumns] = useState(defaultColumns);

  useEffect(() => {
    setActiveColumns(defaultColumns);
  }, [defaultColumns, setActiveColumns]);

  useEffect(() => {
    if (savedSearch.created_at) {
      toastr.info(
        "Viewing Saved Results",
        `Viewing search results from ${moment(
          savedSearch.created_at
        )}. Click "Search" to view latest results`
      );
    }
  }, [savedSearch.created_at]);

  const search = useCallback(
    (query) => {
      if (query.query || (query.pages || []).length > 0) {
        onSearch(makeQuery(query));
      }
    },
    [onSearch]
  );

  const savedSearchData = useJSONL(savedSearch.result);

  const watchlistData = (watchlist || {}).sample_data;

  const results = useMemo(() => {
    console.log("useMemo:results");
    return pollingResults || savedSearchData || watchlistData || [];
  }, [pollingResults, savedSearchData, watchlistData]);

  const resultId = pollingResultId ?? savedSearch.id;

  const [createWatchlist, saveWatchlist] = useWatchlist({
    query,
    watchlist,
    results,
    filters,
  });

  const [coordinationPendingCount, pageCoordination] = useCoordination(
    resultId,
    results.length
  );

  const [rawColumns, columnOptions] = useMemo(() => {
    const raw = makeColumns(facebookToken, flags, pageCoordination);
    const options = _.sortBy(
      raw.map((x) => liftValue(x.Header)),
      (x) => x.label
    );
    return [raw, options];
  }, [facebookToken, flags, pageCoordination]);

  const pageNames = useMemo(
    () => _.fromPairs(results.map((x) => [x.page_id, x.page_name])),
    [results]
  );

  const { setColumnFilter, setFilterSetter, setClearFilters, clearFilters } =
    useColumnFilters(pageNames, rawColumns, activeColumns, setActiveColumns);

  const { entityMap, pageMap, listMap } = useEntityLists();

  const extendedResults = useMemo(() => {
    return results.map((x) => {
      const listIds = _.uniq([
        ...(entityMap.get(x.funding_entity) ?? []),
        ...(pageMap.get(x.page_id) ?? []),
      ]);

      return {
        ...x,
        known: _.sortBy(
          listIds.map(
            (x) => ({ id: x, name: listMap.get(x).name }),
            (x) => x.name
          )
        ),
        coordination: anyCoordination(
          x.page_id,
          pageCoordination[x.page_id],
          minCoordinate
        )
          ? "Yes"
          : "No",
      };
    });
  }, [results, pageCoordination, minCoordinate, entityMap, pageMap, listMap]);

  const columns = useMemo(() => {
    console.debug("useMemo:columns");

    return makeColumns(
      facebookToken,
      flags,
      pageCoordination,
      pageNames,
      minCoordinate,
      setColumnFilter
    ).filter((c) => activeColumns.some((x) => x.value === c.Header));
  }, [
    activeColumns,
    facebookToken,
    pageCoordination,
    pageNames,
    minCoordinate,
    setColumnFilter,
    flags,
  ]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">
        {watchlist ? "Meta Ad Watchlist" : "Meta Ad Search"}
      </h1>

      {watchlist && (
        <WatchlistSettings watchlist={watchlist} onSave={saveWatchlist} />
      )}

      <Row>
        {!facebookToken && (
          <Col lg="12">
            <ConnectFacebook />
          </Col>
        )}
        <Col lg="12">
          <Card>
            <CardHeader className="pb-1">
              <CardTitle tag="h5">Search Query</CardTitle>
              <h6 className="card-subtitle text-muted">
                Search by ad creative term and/or pages
              </h6>
            </CardHeader>
            <CardBody className="pt-1">
              <SearchBar
                onSearch={search}
                onChange={setQuery}
                initialQuery={query}
                isWatchlist={!!watchlist}
                isSearching={isSearching}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {DEFAULT_CHARTS.map((x, i) => (
          <Col className="col-4" key={i}>
            <ChartCard
              initialChart={x}
              data={debouncedRows}
              chartProps={{ countries: query.countries }}
              setFilter={setColumnFilter}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader className="pb-1">
              <CardTitle tag="h5">Search Results</CardTitle>
            </CardHeader>
            <CardBody className="pt-0">
              <Row>
                <Col>
                  <Form className="form-inline">
                    <FormGroup>
                      <Label for="columns">Columns</Label>
                      <CompactMultiSelect
                        id="columns"
                        css={css`
                          width: 225px;
                          margin-left: 5px;
                        `}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={columnOptions}
                        value={activeColumns}
                        onChange={setActiveColumns}
                        isClearable={false}
                      />
                    </FormGroup>

                    <ButtonDropdown
                      className="ml-3 mr-3"
                      isOpen={exportOpen}
                      toggle={() => setExportOpen(!exportOpen)}
                    >
                      <DropdownToggle caret>
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp;Export
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => exportCSV(tableRows)}>
                          CSV
                        </DropdownItem>
                        <DropdownItem onClick={() => exportJSON(tableRows)}>
                          JSON
                        </DropdownItem>
                        <DropdownItem onClick={() => exportJSONL(tableRows)}>
                          JSONL
                        </DropdownItem>
                        {flags.includes("targeting") && (
                          <React.Fragment>
                            <DropdownItem
                              onClick={() => exportTargetingCSV(tableRows)}
                            >
                              Targeting: Combined
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => exportGeoCSV(tableRows)}
                            >
                              Targeting: Geo.
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => exportDemographicsCSV(tableRows)}
                            >
                              Targeting: Demo.
                            </DropdownItem>
                          </React.Fragment>
                        )}
                      </DropdownMenu>
                    </ButtonDropdown>
                    {!watchlist && (
                      <Button onClick={createWatchlist}>
                        <FontAwesomeIcon icon={faStar} /> Save as Watchlist
                      </Button>
                    )}
                    <Button
                      color="warning"
                      onClick={clearFilters}
                      className="ml-3"
                      disabled={!Object.keys(filters || {}).length}
                    >
                      <FontAwesomeIcon icon={faTimes} /> Clear Filters
                    </Button>
                    <CoordinationOption
                      searchId={resultId}
                      coordinationPendingCount={coordinationPendingCount}
                      numResults={results.length}
                      minCoordination={minCoordinate}
                      setMinCoordination={setMinCoordination}
                    />
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col lg="12" className="mt-3">
                  {isSearching && (
                    <Row>
                      <Col>
                        <div className="text-center mt-5">
                          <Loader
                            type="Grid"
                            color="#9d7bd8"
                            height={100}
                            width={100}
                          />
                        </div>
                        <Table className="mt-5">
                          <thead>
                            <tr>
                              <th>Subquery</th>
                              <th>Query Status</th>
                              <th>Result Page #</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.values(progress).map(
                              ({ query, page, status }) => (
                                <tr key={query.search_terms}>
                                  <td>{query.search_terms}</td>
                                  <td>{status}</td>
                                  <td>{page}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                  {searchError && <SearchError {...searchError} />}
                  {!searchError && !isSearching && (
                    <div
                      css={css`
                        overflow-x: auto;
                      `}
                    >
                      <DetailTable
                        columns={columns}
                        data={extendedResults}
                        onChange={setTableRows}
                        onFilter={setFilters}
                        setFilterSetter={setFilterSetter}
                        setClearFilters={setClearFilters}
                        initialFilters={filters}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

// FacebookLayout.whyDidYouRender = true;

export default FacebookLayout;
