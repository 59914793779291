import { useState } from "react";
import useAsyncEffect from "use-async-effect";

export default function useAsyncValue(generateValue, initialValue, deps) {
  const [state, setState] = useState(initialValue);

  useAsyncEffect(async () => {
    setState(await generateValue());
  }, [setState, ...deps]);

  return state;
}
