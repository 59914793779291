import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import useAsyncValue from "../useAsyncValue";
import { getAPI, postAPI } from "../WebAPI";
import UserContext from "./common/UserContext";
import { useContext, useCallback, useState } from "react";
import DOMPurify from "dompurify";

const ModalContext = ({ policy, onAccept }) => (
  <>
    <ModalHeader>{policy.title}</ModalHeader>
    <ModalBody>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(policy.text),
        }}
      />
    </ModalBody>
    <ModalFooter>
      <Button onClick={onAccept}>Accept</Button>
    </ModalFooter>
  </>
);

export default () => {
  const [accepted, setAccepted] = useState(false);
  const { token } = useContext(UserContext);

  const policies = useAsyncValue(
    async () => {
      return getAPI("/api/policies/", token);
    },
    [],
    [token]
  );

  const acceptPolicy = useCallback(
    async (policy) => {
      setAccepted(true);
      await postAPI("/api/policies/accept/", { policy: policy.id }, { token });
    },
    [setAccepted]
  );

  const policy = policies[0];

  return (
    <Modal isOpen={Boolean(policy) && !accepted}>
      {policy && (
        <ModalContext
          policy={policy}
          onAccept={async () => {
            await acceptPolicy(policy);
          }}
        />
      )}
    </Modal>
  );
};
